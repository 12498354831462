<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    id?: string
    name?: string
    accent?: 'light' | 'dark'
    disabled?: boolean
  }>(),
  {
    accent: 'dark',
    disabled: false,
  },
);

const modelValue = defineModel<boolean | null>('modelValue', {
  default: false,
});

const clickHandler = () => {
  if (props.disabled) return;
  modelValue.value = !modelValue.value;
};
</script>

<template>
  <div
    :class="[accent, { disabled }]"
    data-component-name="AppCheckbox"
    @click="clickHandler"
  >
    <input
      v-model="modelValue"
      :disabled
      :name
      type="checkbox"
      :id
      @click.self.stop
    >

    <SvgEmptySquare v-if="modelValue === false" class="unchecked" />
    <SvgCheckInSquare v-else-if="modelValue === true" class="checked" />
    <SvgMinusInSquare v-else-if="modelValue === null" class="indeterminate" />
  </div>
</template>

<style scoped lang="scss">
@use "_/mixins/size";
@use "_/mixins/flex";
@use "_/fn";

[data-component-name="AppCheckbox"] {
  @include flex.center-center;
  @include size.fixed(1.25rem);

  cursor: pointer;
  transition: background-color 0.15s ease;

  input[type="checkbox"] {
    display: none;
  }

  // ACCENTS
  &.dark {
    svg.unchecked {
      fill: fn.token('icon-primary');
    }

    svg.checked,
    svg.indeterminate {
      fill: fn.token('icon-action');
    }
  }

  &.light {
    svg.unchecked,
    svg.checked,
    svg.indeterminate {
      fill: fn.token('icon-reversed');
    }
  }

  // STATES
  &:hover {
    background-color: rgba(fn.token('accent-hover'), 16%);
  }

  &:active {
    background-color: rgba(fn.token('accent-hover'), 32%);
  }

  &.disabled {
    cursor: not-allowed;

    svg {
      fill: fn.token('icon-tertiary');
    }

    &:hover,
    &:active {
      background-color: unset;
    }
  }
}
</style>
